.about-me-section {
    background-color: #000;
    color: #fff;
    position: relative;
    overflow: hidden;
    padding: 12rem 0;
}

.about-me-section::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(76, 29, 149, 0.1) 0%, rgba(0, 0, 0, 0) 70%);
    z-index: 1;
}

.expertise-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 2.5rem;
    position: relative;
    z-index: 2;
}

.expertise-card {
    background: rgba(76, 29, 149, 0.08);
    border: 1px solid rgba(76, 29, 149, 0.2);
    border-radius: 20px;
    padding: 2.5rem;
    transition: all 0.4s ease;
    position: relative;
    overflow: hidden;
}

.expertise-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(147, 51, 234, 0.5),
        transparent
    );
    transform: translateX(-100%);
    transition: transform 0.8s ease;
}

.expertise-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 40px rgba(76, 29, 149, 0.15);
    background: rgba(76, 29, 149, 0.12);
}

.expertise-card:hover::before {
    transform: translateX(100%);
}

.expertise-icon-wrapper {
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expertise-icon {
    font-size: 2.5rem;
    position: relative;
    z-index: 2;
}

.expertise-icon-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(147, 51, 234, 0.1);
    border-radius: 16px;
    transition: all 0.3s ease;
}

.expertise-card:hover .expertise-icon-bg {
    transform: translate(-50%, -50%) rotate(45deg);
    background: rgba(147, 51, 234, 0.2);
}

.expertise-skills {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 1rem;
}

.skill-item {
    background: rgba(147, 51, 234, 0.15);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    color: #e4e4e7;
    transition: all 0.3s ease;
}

.skill-item:hover {
    background: rgba(147, 51, 234, 0.25);
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .expertise-grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .expertise-card {
        padding: 2rem;
    }

    .skills-list {
        gap: 0.5rem;
    }

    .skill-item {
        font-size: 0.85rem;
        padding: 0.4rem 0.8rem;
    }
}