.contact-section {
    background-color: #ffffff;
    padding: 80px 0;
    color: #000000;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
}

h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
}

.subheading {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 30px;
    max-width: 600px;
}

.email-link {
    color: #000;
    text-decoration: underline;
    font-weight: bold;
    transition: color 0.3s ease;
}

.email-link:hover {
    color: #4a4a4a;
}

.alternative {
    font-size: 16px;
    margin-bottom: 40px;
}

.social-links {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    gap: 20px;
}

.social-links a {
    color: #000;
    font-size: 24px;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #4a4a4a;
}

@media (max-width: 768px) {
    .contact-section {
        padding: 60px 0;
    }

    h2 {
        font-size: 36px;
    }

    .subheading {
        font-size: 16px;
    }

    .social-links {
        position: static;
        justify-content: flex-end;
        margin-top: 30px;
    }
}