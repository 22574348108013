.speaking-engagements-section {
    background-color: #000;
    color: #fff;
    padding: 12rem 0;
}

.speech-showcase {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.speech-images {
    position: relative;
    width: 70%;
    height: 60vh;
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 2rem;
}

.speech-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.speech-image.active {
    opacity: 1;
}

.speech-info {
    text-align: center;
}

.speech-thumbnails {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
}

.speech-thumbnail {
    width: 80px;
    height: 60px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0.6;
}

.speech-thumbnail:hover,
.speech-thumbnail.active {
    opacity: 1;
    transform: scale(1.1);
    box-shadow: 0 0 15px rgba(76, 29, 149, 0.5);
}

@media (max-width: 768px) {
    .speech-images {
        width: 90%;
        height: 40vh;
    }

    .speech-thumbnails {
        flex-wrap: wrap;
    }
}