.coming-soon-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #000000, #4B0082);
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
    overflow: hidden;
  }
  
  .coming-soon-content {
    z-index: 10;
  }
  
  .coming-soon-title {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .coming-soon-subtitle {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  .coming-soon-button {
    background-color: #9333ea;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .coming-soon-button:hover {
    background-color: #7e22ce;
  }
  
  .dot {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    pointer-events: none;
  }