.custom-cursor {
  width: 30px;
  height: 30px;
  border: 0.5px solid #ffffff; /* Reduced border thickness */
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  transition: all 0.1s ease, width 0.3s ease, height 0.3s ease;
  transform: translate(-50%, -50%);
}

.custom-cursor.clicked {
  animation: cursorClick 0.5s ease;
}

.custom-cursor.hovering {
  width: 80px;
  height: 80px;
}

@keyframes cursorClick {
  0%, 100% {
    width: 30px;
    height: 30px;
  }
  50% {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 768px) {
  .custom-cursor {
    display: none;
  }
}