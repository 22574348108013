/* Header.css */

.menu-item {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.menu-item-text {
    display: inline-block;
    transition: transform 0.3s ease;
}

.menu-item::before,
.menu-item::after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.menu-item::before {
    top: 0;
}

.menu-item::after {
    top: 100%;
}

.menu-item:hover .menu-item-text {
    transform: translateY(-100%);
}

.menu-item:hover::before {
    transform: translateY(-100%);
}

.menu-item:hover::after {
    transform: translateY(-100%);
}

/* Mobile menu animations */
@media (max-width: 1023px) {
    .Dialog-panel {
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
    }

    .Dialog-panel[open] {
        transform: translateX(0);
    }
}