.case-study-container {
    background: linear-gradient(to bottom, #000000, #1e0936);
}

.hero-section {
    position: relative;
    padding-top: 2rem;
}

.project-links {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.project-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: rgba(147, 51, 234, 0.1);
    border: 1px solid rgba(147, 51, 234, 0.2);
    border-radius: 2rem;
    color: white;
    transition: all 0.3s ease;
}

.project-link:hover {
    background: rgba(147, 51, 234, 0.2);
    transform: translateY(-2px);
}

.project-details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    margin-top: 3rem;
}

.detail-card {
    padding: 1.5rem;
    background: rgba(76, 29, 149, 0.08);
    border: 1px solid rgba(76, 29, 149, 0.2);
    border-radius: 1rem;
}

.main-image-container {
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

.main-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.content-grid {
    display: grid;
    gap: 4rem;
    margin-top: 4rem;
}

.content-section {
    max-width: 800px;
}

.results-list {
    list-style: none;
    padding: 0;
}

.results-list li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;
    color: #e5e7eb;
}

.results-list li::before {
    content: '→';
    position: absolute;
    left: 0;
    color: #9333ea;
}

.tech-tags-cs-detail {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.tech-tag-cs-detail {
    padding: 0.5rem 1rem;
    background: rgba(147, 51, 234, 0.1);
    border: 1px solid rgba(147, 51, 234, 0.2);
    border-radius: 2rem;
    color: white;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

.gallery-item {
    border-radius: 1rem;
    overflow: hidden;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .project-details-grid {
        grid-template-columns: 1fr;
    }

    .gallery-grid {
        grid-template-columns: 1fr;
    }

    .project-links {
        flex-direction: column;
    }

    .project-link {
        width: 100%;
        justify-content: center;
    }
}