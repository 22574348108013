.casestudies-container {
  background: linear-gradient(to bottom, #000000, #1e0936);
}

.categories-filter {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.category-btn {
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  background: rgba(147, 51, 234, 0.1);
  color: white;
  border: 1px solid rgba(147, 51, 234, 0.2);
  transition: all 0.3s ease;
}

.category-btn:hover {
  background: rgba(147, 51, 234, 0.2);
  transform: translateY(-2px);
}

.category-btn.active {
  background: rgba(147, 51, 234, 0.3);
  border-color: rgba(147, 51, 234, 0.5);
}

.cases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
}

.case-card {
  height: 100%;
  background: rgba(76, 29, 149, 0.08);
  border: 1px solid rgba(76, 29, 149, 0.2);
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.3s ease;
}

.case-image-wrapper {
  position: relative;
  height: 240px;
  overflow: hidden;
}

.case-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.case-card:hover .case-image {
  transform: scale(1.05);
}

.case-overlay {
  position: absolute;
  inset: 0;
  background: rgba(76, 29, 149, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.tech-tag {
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  color: white;
  font-size: 0.875rem;
}

.case-content {
  padding: 1.5rem;
}

.read-more-btn {
  padding: 0.5rem 1rem;
  background: rgba(147, 51, 234, 0.2);
  border-radius: 0.5rem;
  color: white;
  pointer-events: none;
}

.read-more-btn:hover {
  background: rgba(147, 51, 234, 0.3);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .cases-grid {
    grid-template-columns: 1fr;
  }
}

.back-button {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background: rgba(147, 51, 234, 0.1);
  border: 1px solid rgba(147, 51, 234, 0.2);
  border-radius: 2rem;
  color: white;
  font-size: 0.875rem;
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(147, 51, 234, 0.2);
  transform: translateX(-5px);
}

.back-button svg {
  margin-right: 0.5rem;
}

.case-card-link {
  text-decoration: none;
  display: block;
  transition: transform 0.3s ease;
}

.case-card-link:focus {
  outline: none;
}

.case-card-link:focus-visible {
  outline: 2px solid rgba(147, 51, 234, 0.5);
  border-radius: 1rem;
}