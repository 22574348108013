@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes moveLight {
  0%, 100% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
}

.hero-section {
  position: relative;
  overflow: hidden;
}

.bg-gradient-custom {
  background: 
      radial-gradient(circle at top center, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 60%),
      linear-gradient(to bottom, #22003f, #0a0a1a);
  background-size: 200% 200%, 100% 100%;
  animation: moveLight 6s ease infinite;
}

@media (max-width: 768px) {
  .hero-title {
      font-size: 160px;
  }
}

.chevron {
  opacity: 25%;
}