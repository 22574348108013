.why-work-with-me-section {
    background-color: #000;
    color: #fff;
    position: relative;
    overflow: hidden;
    padding: 12rem 0;
}

.why-work-with-me-section::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(76, 29, 149, 0.1) 0%, rgba(0, 0, 0, 0) 70%);
    z-index: 1;
}

.reasons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2.5rem;
    position: relative;
    z-index: 2;
    margin-bottom: 4rem;
}

.reason-card {
    background: rgba(76, 29, 149, 0.08);
    border: 1px solid rgba(76, 29, 149, 0.2);
    border-radius: 20px;
    padding: 2.5rem;
    transition: all 0.4s ease;
    position: relative;
    overflow: hidden;
}

.reason-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(147, 51, 234, 0.5),
        transparent
    );
    transform: translateX(-100%);
    transition: transform 0.8s ease;
}

.reason-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 40px rgba(76, 29, 149, 0.15);
    background: rgba(76, 29, 149, 0.12);
}

.reason-card:hover::before {
    transform: translateX(100%);
}

.reason-icon-wrapper {
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reason-icon {
    font-size: 2.5rem;
    position: relative;
    z-index: 2;
}

.icon-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(147, 51, 234, 0.1);
    border-radius: 16px;
    transition: all 0.3s ease;
}

.reason-card:hover .icon-background {
    transform: translate(-50%, -50%) rotate(45deg);
    background: rgba(147, 51, 234, 0.2);
}

.cta-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 4rem;
    position: relative;
    z-index: 2;
}

.cta-button {
    padding: 1rem 2rem;
    border-radius: 8px;
    font-weight: 600;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.cta-button.primary {
    background: rgba(147, 51, 234, 0.2);
    border: 1px solid rgba(147, 51, 234, 0.5);
}

.cta-button.secondary {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.cta-button:hover {
    border-color: rgba(147, 51, 234, 0.8);
}

.cta-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        45deg,
        transparent,
        rgba(147, 51, 234, 0.2),
        transparent
    );
    transform: translateX(-100%);
    transition: transform 0.6s ease;
}

.cta-button:hover::before {
    transform: translateX(100%);
}

@media (max-width: 768px) {
    .reasons-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .cta-container {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .cta-button {
        width: 100%;
        max-width: 300px;
    }
}