.experience-section {
    position: relative;
    padding: 100px 0;
    background: linear-gradient(to bottom, #000000, #0a0a0a);
    min-height: 100vh;
    overflow-x: hidden;
    width: 100%;
    max-width: 100vw;
}

.timeline-container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    overflow-x: hidden;
    width: 100%;
}

.timeline-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: calc(100% - 100px);
    background: linear-gradient(to bottom, 
        rgba(76, 29, 149, 0.1),
        rgba(76, 29, 149, 0.5),
        rgba(76, 29, 149, 0.1)
    );
}

.experience-timeline-item {
    position: relative;
    margin: 60px 0;
    width: calc(50% - 30px);
    max-width: 100%;
    box-sizing: border-box;
}

.experience-timeline-item::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: #4c1d95;
    border-radius: 50%;
    top: 24px;
}

.experience-timeline-item.left {
    left: 0;
}

.experience-timeline-item.right {
    left: calc(50% + 30px);
}

.experience-timeline-item.left::before {
    right: -38px;
}

.experience-timeline-item.right::before {
    left: -38px;
}

.timeline-content {
    background: rgba(76, 29, 149, 0.1);
    padding: 24px;
    border-radius: 12px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(76, 29, 149, 0.2);
    transition: transform 0.3s ease;
    max-width: 100%;
    box-sizing: border-box;
}

.timeline-content:hover {
    transform: translateY(-5px);
}

.experience-date {
    color: #9333ea;
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 8px;
}

.company-logos {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    flex-wrap: wrap;
    max-width: 100%;
}

.company-logos img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
    max-width: 100%;
    height: auto;
}

.company-logos img:hover {
    filter: grayscale(0%);
}

.view-company-btn {
    display: inline-block;
    margin-top: 16px;
    padding: 8px 16px;
    background: rgba(76, 29, 149, 0.3);
    border-radius: 4px;
    color: white;
    text-decoration: none;
    transition: background 0.3s ease;
}

.view-company-btn:hover {
    background: rgba(76, 29, 149, 0.5);
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .timeline-line {
        left: 20px;
    }

    .experience-timeline-item {
        width: calc(100% - 50px);
        margin: 40px 0;
    }

    .experience-timeline-item.left,
    .experience-timeline-item.right {
        left: 50px;
    }

    .experience-timeline-item.left::before,
    .experience-timeline-item.right::before {
        left: -38px;
    }

    .timeline-content {
        padding: 20px;
    }

    .company-logos img {
        width: 40px;
        height: 40px;
    }
}