.project-showcase-section {
    background-color: #000;
    color: #fff;
    padding: 6rem 0;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.showcase-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 2rem;
}

.showcase-header {
    margin-bottom: 4rem;
}

.preview-section {
    width: 100%;
    height: 500px;
    margin-bottom: 4rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-content {
    width: 55%;
    height: 100%;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    background: rgba(76, 29, 149, 0.1);
}

.preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.video-wrapper iframe {
    width: 100%;
    height: 100%;
}

.projects-scroll-container {
    width: 100%;
    overflow-x: auto;
    padding: 2rem 0;
}

.projects-scroll-container::-webkit-scrollbar {
    height: 8px;
}

.projects-scroll-container::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

.projects-scroll-container::-webkit-scrollbar-thumb {
    background: rgba(147, 51, 234, 0.5);
    border-radius: 4px;
}

.projects-track {
    display: flex;
    gap: 2rem;
    padding: 0.5rem;
}

.project-card {
    flex: 0 0 400px;
    background: rgba(76, 29, 149, 0.08);
    border: 1px solid rgba(76, 29, 149, 0.2);
    border-radius: 20px;
    padding: 2rem;
    transition: all 0.4s ease;
    cursor: pointer;
}

.project-card.active {
    background: rgba(76, 29, 149, 0.15);
    transform: translateY(-4px);
    box-shadow: 0 20px 40px rgba(76, 29, 149, 0.2);
}

.project-card:hover {
    transform: translateY(-4px);
}

.tech-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 1rem 0;
}

.tech-tag {
    background: rgba(147, 51, 234, 0.15);
    padding: 0.4rem 0.8rem;
    border-radius: 20px;
    font-size: 0.85rem;
    color: #e4e4e7;
    transition: all 0.3s ease;
}

.responsibility-list {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
}

.responsibility-list li {
    padding-left: 1.5rem;
    position: relative;
    margin-bottom: 0.5rem;
    color: #a3a3a3;
}

.responsibility-list li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #9333ea;
}

.view-project-btn {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    background: rgba(147, 51, 234, 0.2);
    border-radius: 8px;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
}

.view-project-btn:hover {
    background: rgba(147, 51, 234, 0.3);
}

@media (max-width: 768px) {
    .preview-section {
        height: auto;
        margin-bottom: 0;
    }

    .project-card {
        flex: 0 0 300px;
        padding: 1.5rem;
    }

    .tech-stack {
        gap: 0.4rem;
    }

    .tech-tag {
        font-size: 0.8rem;
        padding: 0.3rem 0.6rem;
    }
}