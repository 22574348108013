.bio-section {
    position: relative;
    min-height: 100vh;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.bio-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 2rem;
    position: relative;
    z-index: 2;
    width: 100%;
}

.bio-content {
    max-width: 900px;
}

.bio-tagline {
    font-size: 0.875rem;
    letter-spacing: 0.2em;
    margin-bottom: 2rem;
    color: #9333ea;
    font-weight: 500;
}

.bio-title {
    font-size: clamp(2.5rem, 8vw, 6rem);
    line-height: 1.1;
    margin-bottom: 3rem;
    font-weight: 100;
}

.bio-subtitle {
    font-weight: 700;
    display: inline-block;
}

.accent-dot {
    color: #ef4444;
    display: inline-block;
    animation: pulse 2s infinite;
}

.bio-actions {
    display: flex;
    gap: 2rem;
    margin-top: 4rem;
}

.bio-button {
    position: relative;
    padding: 1rem 2rem;
    border: 2px solid transparent;
    border-radius: 4px;
    font-weight: 600;
    font-size: 1rem;
    transition: all 0.3s ease;
    overflow: hidden;
    min-width: 200px;
    text-align: center;
}

.bio-button.primary {
    background: #9333ea;
    color: white;
    border-color: #9333ea;
}

.bio-button.secondary {
    background: transparent;
    border-color: white;
    color: white;
}

.button-shine {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to right,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    transform: translateX(-100%);
    transition: transform 0.6s ease;
}

.bio-button:hover .button-shine {
    transform: translateX(100%);
}

.bio-button:hover {
    box-shadow: 0 0 20px rgba(147, 51, 234, 0.3);
}

.bio-background {
    position: absolute;
    inset: 0;
    z-index: 1;
    overflow: hidden;
}

.gradient-sphere {
    position: absolute;
    width: 600px;
    height: 600px;
    right: -100px;
    top: 50%;
    transform: translateY(-50%);
    background: radial-gradient(circle, rgba(147, 51, 234, 0.2) 0%, transparent 70%);
    filter: blur(40px);
    animation: float 8s ease-in-out infinite;
}

.grid-pattern {
    position: absolute;
    inset: 0;
    background-image: 
        linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
        linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 50px 50px;
    opacity: 0.5;
}

@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

@keyframes float {
    0%, 100% {
        transform: translate(-50%, -50%);
    }
    50% {
        transform: translate(-50%, -40%);
    }
}

@media (max-width: 768px) {
    .bio-actions {
        flex-direction: column;
        gap: 1rem;
    }

    .bio-button {
        width: 100%;
    }

    .gradient-sphere {
        width: 300px;
        height: 300px;
        right: -50px;
    }
}

@media (max-width: 480px) {
    .bio-container {
        padding: 0 1rem;
    }

    .bio-tagline {
        font-size: 0.75rem;
    }
}
